// cSpell: ignore tilly
import React from 'react';
import Home from '../../components/home/v3/Home';
import Layout from '../../layouts/index';

class HomePageTilly extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { props } = this;
    return (
      <Layout routeSlug="HomePageTilly">
        <Home
          {...props}
          altTag="retouche couture avec Tilly"
          headerExtraText={'Tilly s’écrit avec un "i" et non un "y" : Tilli'}
        />
      </Layout>
    );
  }
}

export default HomePageTilly;
